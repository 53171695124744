<template>
	<v-row class="header" v-if="formattedData">
		<v-col offset="2" :cols="$vuetify.breakpoint.mobile ? 8 : 6">
			<div :class="'target color-' + formattedData.target.slug">
				<span class="font-weight-bold">Notizia </span>
				<span class="separator">&bull;</span>
				{{ formattedData.target.name }}
			</div>

			<h1 class="font-size-50">{{ formattedData.title }}</h1>
			<h2
				v-if="formattedData.cardInfo.subtitle_news"
				class="font-size-40"
			>
				{{ formattedData.cardInfo.subtitle_news }}
			</h2>

			<div class="button" v-if="formattedData.cardInfo.button_url">
				<v-btn
					:color="colorsObject[formattedData.target.slug]"
					depressed
					class="loanButton white--text"
					name="Prestito"
					>Prestito</v-btn
				>
			</div>
		</v-col>
		<v-col :cols="$vuetify.breakpoint.mobile ? 12 : 3">
			<img
				:src="formattedData.srcImage"
				:class="{ image: true, center: $vuetify.breakpoint.mobile }"
			/>
		</v-col>
	</v-row>
</template>

<script>
import { formatCategoriesAndTargets } from "@/mixins/formatCategoriesAndTargets";
import { GET_POST, GET_POST_PREVIEW } from "@/queries/Posts.js";
export default {
	data() {
		return {
			formattedData: null
		};
	},
	mixins: [formatCategoriesAndTargets],
	props: {
		postSlug: {
			type: String,
			default: null,
			required: true
		}
	},
	computed: {
		pageColor() {
			return this.colorsObject[this.formattedData.target.slug];
		}
	},
	async mounted() {
			if (this.postSlug) {
				let query;
				let isPreview = this.$route.query && this.$route.query.preview;
			if (isPreview) {
				query = {
					query: GET_POST_PREVIEW(this.postSlug)
				};
			} else {
				query = {
					query: GET_POST(this.postSlug)
				};
			}
			
			let data = await this.$apollo.query(query);

			if (isPreview) {
				data = data && data.data && data.data.post || false;
			} else {
				data = data && data.data && data.data.posts && data.data.posts.nodes && data.data.posts.nodes[0]? data.data.posts.nodes[0] : false;
			}

			data.blocks = JSON.parse("[" + data.blocksJSON + "]")[0];
			let newData = this.formatCategoriesAndTargets(data)[0];
			let cardInfo = JSON.parse(newData.blocks[0].attributes.fields);
			this.formattedData = {
				title: newData.title,
				cardInfo: cardInfo,
				blocks: newData.blocks.slice(1, newData.length),
				srcImage: newData.featuredImage
					? newData.featuredImage.node.sourceUrl
					: "/wp-content/uploads/2020/11/no-iamge-placeholder.jpg",
				target: newData.target,
				subCategory: newData.subCategory,
				mainCategory: newData.mainCategory
			};
		}
	}
};
</script>

<style lang="scss" scoped>
h1 {
}
h2 {
	font-weight: normal;
	padding-bottom: 30px;
}
.target {
	padding-bottom: $spacing-0;
	text-transform: uppercase;
	font-size: $font-size-14;
}

.header {
	padding-top: $spacing-1;
}
.image.center {
	transform: translateX(-50%);
	left: 50%;
}
.image {
	margin: auto;
	height: 250px;
	width: 250px;
	max-width: 250px;
	max-height: 250px;
	border-radius: 100%;
	position: relative;
}

.loanButton {
	border-radius: 50px 50px 50px 5px;
}
@media only screen and (max-width: 768px) {
	#app .col {
		width: 90%;
		max-width: 90%;
		margin: auto;
	}
	#app h1 {
		font-size: $font-size-35;
	}
	.image {
		max-width: 100%;
	}
}
@media only screen and (max-width: 480px) {
	.image {
		margin: auto;
		height: 200px;
		width: 200px;
	}
}
</style>
